#resume {
  .resume-left {
    h3 {
      margin-top: 25px;
      margin-bottom: 15px;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
      margin-top: 75px;
    }
  }

  .page-description {
    margin: 0 auto;
    max-width: 600px;
    text-align: center;
    margin-bottom: 20px;
    padding: 0 15px;
  }

  .summary-container {
    margin: 0 auto;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .picture {
      height: 120px;
      width: 120px;
      flex-shrink: 0;
      border-radius: 15px;
      margin-right: 30px;
      overflow: hidden;
      background: var(--bg);
      border: 1px solid #ccc;

      img {
        height: 100%;
      }
    }

    .summary {
      flex: 1;
    }
  }

  .personal-details {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .resume-left {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .resume-right {
    flex: 0 0 70%;
    max-width: 70%;
  }
}
