html,
body.no-margin {
  margin: 0;
  min-height: 100%;
  font-family: "Google Sans", Helvetica, Arial, Sans-Serif;
  font-size: 15px;
  line-height: 1.25;

  @media print {
    margin: 0 10px;
  }
}

@page {
  margin: 0cm;
}

a {
  color: var(--logo-secondary);
}

section.content {
  padding-bottom: 20px;
}

h1.title {
  &:after {
    width: 5px;
    height: 5px;
    margin-bottom: 8px;
  }
}

h2.title {
  width: 100%;
  font-weight: normal;
  font-size: 34px;
  margin: 0;
  margin-bottom: 30px;
  text-align: center;

  &:after {
    width: 5px;
    height: 5px;
    margin-bottom: 8px;
  }

  @media print {
    font-size: 28px;
  }
}

h3.title {
  width: 100%;
  font-weight: normal;
  font-size: 24px;
  margin: 0;
  display: inline-block;
  margin-bottom: 10px;

  &:after {
    width: 4px;
    height: 4px;
    margin-bottom: 6px;
  }

  @media print {
    font-size: 20px;
  }
}

h4.title {
  width: 100%;
  font-weight: normal;
  font-size: 18px;
  margin: 0;
  margin-bottom: 20px;
  display: inline-block;

  &:after {
    width: 3px;
    height: 3px;
    margin-bottom: 5px;
  }
}

h1.title:after,
h2.title:after,
h3.title:after,
h4.title:after,
.card-title:after {
  content: "";
  margin-left: 1px;
  border-radius: 50%;
  vertical-align: bottom;
  display: inline-block;
  -webkit-print-color-adjust: exact;
  background-color: var(--primary);
}

@media screen and (max-width: 768px) {
  h2.title {
    font-size: 30px;
  }

  h3.title {
    font-size: 20px;
  }
}

stop[class$="gradient-primary-start"] {
  stop-color: var(--gradient-primary-start);
}

stop[class$="gradient-primary-stop"] {
  stop-color: var(--gradient-primary-stop);
}

svg {
  text[font-family="Consolas"],
  tspan[font-family="Consolas"] {
    font-family: "Consolas Poly";
  }
}

.page-content {
  margin-top: 60px;

  @media (max-width: 768px) {
    margin-top: 30px;
  }

  &.small {
    @media (min-width: 992px) {
      max-width: 960px !important;
    }
  }

  @media print {
    width: 100%;
    max-width: 100% !important;
    padding: 0 20px;
    margin-top: 0;
  }
}

@media not print {
  .print {
    display: none !important;
  }

  .dont-print {
    display: block;
  }
}

@media print {
  .print {
    display: block;
  }

  .dont-print {
    display: none !important;
  }
}

.image {
  opacity: 0;
  transition: opacity 400ms ease;

  &.image-visible {
    opacity: 1;
  }
}
