.timeline-item {
  position: relative;
  padding: 5px 0px;
  padding-left: 23px;

  &:first-child:before {
    top: 12px;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0px;
    top: -10px;
    bottom: 8px;
    width: 3px;
    -webkit-print-color-adjust: exact;
    background-color: var(--timeline);
    border-radius: 10px;
  }

  .indicator {
    width: 7px;
    height: 7px;
    border-radius: 50px;
    margin-right: 18px;
    position: absolute;
    left: -2px;
    top: 10px;
    z-index: 1;
    background-color: var(--bg);
    box-shadow: 0px 0px 0px 2px var(--timeline);
    -webkit-print-color-adjust: exact;
  }

  .inner-item {
    display: flex;
    flex-direction: row;

    .logo {
      flex-shrink: 0;
      height: 36px;
      max-height: 36px;
      margin-right: 15px;
      width: 36px;
      max-width: 36px;
      object-fit: scale-down;
      filter: var(--icon-filter);
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      padding-bottom: 5px;

      .title {
        display: flex;
        font-size: 16px;
        align-items: center;
        position: relative;
        z-index: 1;
      }

      .subtitle {
        font-size: 15px;
        margin-top: 2px;
        color: var(--text-secondary);
        display: flex;
      }

      .description {
        font-size: 14px;
        margin-top: 5px;
      }

      .timespan {
        font-size: 14px;
        color: var(--text-secondary);
        margin-left: auto;
      }
    }
  }

  .children {
    position: relative;
    padding-top: 0px;

    &:first-child:before {
      top: 20px;
    }

    .curve {
      position: absolute;
      left: -23px;
      top: -16px;
      width: 26px;
      height: 26px;

      * {
        stroke-width: 3px;
        stroke: var(--timeline);
      }
    }
  }
}
