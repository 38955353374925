// Firefox scrollbars
@-moz-document url-prefix() {
  * {
    scrollbar-color: var(--scrollbar-thumb) var(--card-bg);
    scrollbar-width: thin;
  }
}

// Chrome scrollbars
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  *::-webkit-scrollbar-thumb {
    background: var(--scrollbar-thumb);
    border: 1px solid var(--card-bg);
  }

  *::-webkit-scrollbar-track {
    background: var(--card-bg);
  }
}

// IE scrollbars
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  * {
    scrollbar-face-color: var(--scrollbar-thumb);
    scrollbar-shadow-color: var(--scrollbar-thumb);
    scrollbar-track-color: var(--card-bg);
    scrollbar-arrow-color: var(--scrollbar-thumb);
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}
