.btn {
  display: inline-flex;
  position: relative;
  padding: 8px 16px;
  border-radius: 8px;
  text-decoration: none;
  font-size: 15px;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 10px;
    fill: currentColor;
    flex-shrink: 0;
  }

  &.btn-large {
    padding: 12px 16px;
  }

  &.btn-primary {
    background: var(--primary);
    color: #fff;

    &.btn-outline {
      border: 1px solid var(--primary);
    }
  }

  &:after {
    content: "";
    position: absolute;
    background-color: transparent;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transition: background-color 250ms;
  }

  &:hover:after {
    background-color: rgba(255, 255, 255, 0.15);
  }

  &:active:after {
    background-color: rgba(0, 0, 0, 0.15);
  }

  &.btn-text,
  &.btn-outline {
    background: transparent;
    color: var(--primary);

    &:after {
      opacity: 0.15;
    }

    &:hover:after {
      background-color: var(--primary);
      opacity: 0.15;
    }

    &:active:after {
      background-color: var(--primary);
      opacity: 0.25;
    }
  }
}
