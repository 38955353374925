#app {
  width: 100%;
  min-height: 100vh;
  padding-top: $nav-bar-height;
  position: relative;
  color: var(--text);
  background-color: var(--bg);
  -webkit-print-color-adjust: exact;

  .logo {
    #prefix__logo-bottom {
      fill: var(--logo-secondary);
    }

    #prefix__logo-top {
      fill: var(--logo-primary);
    }
  }

  @media print {
    background-color: var(--card-bg);
  }

  .bubble-corner {
    position: absolute;
    top: -150px;
    right: 0;
    width: 34%;

    @media (max-width: 768px) {
      display: none;
    }

    @media screen and (max-width: 1350px) {
      top: -100px;
    }

    @media screen and (max-width: 1250px) {
      top: -75px;
    }

    @media screen and (max-width: 1000px) {
      top: -50px;
    }

    @media screen and (min-width: 1600px) {
      top: -300px;
      width: 38%;
    }
  }

  .corner {
    position: absolute;
    top: 0;
    right: 0;

    &.corner-top {
      width: 32%;
      opacity: var(--corner-top-opacity);
    }

    &.corner-top2 {
      opacity: 1;
      width: 32%;
    }

    &.corner-bottom {
      width: 43%;
      opacity: var(--corner-bottom-opacity);
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 0;

    .corner {
      display: none;
    }
  }
}
