#main-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 30px 0;

  .logo {
    height: 64px;
    width: 64px;
  }

  .footer-info {
    display: flex;
    flex-direction: column;
    margin-left: 20px;

    .copyright {
      font-size: 16px;
    }

    .social-icons {
      padding: 0;
      margin-top: 5px;
      background-color: transparent;
    }
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 64px;

    .logo {
      width: 42px;
      height: 42px;
    }

    .footer-info {
      margin-left: 10px;
    }
  }
}
