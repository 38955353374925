.nav-bar {
  width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  position: fixed;
  top: 0;
  z-index: 10;
  transition: background-color 400ms, box-shadow 400ms;
  background-color: var(--card-bg);

  .container {
    height: $nav-bar-height;
    align-items: center;

    @media print {
      height: auto;
      padding: 20px 0px;
    }
  }

  .logo {
    align-items: center;
    text-decoration: none;
    font-size: 20px;
    color: var(--text);

    @media print {
      font-size: 20px;

      svg {
        height: 35px;
      }
    }

    svg {
      margin-right: 15px;
    }
  }

  .locale-container {
    display: flex;
    align-items: center;
    margin-left: 15px;
    background: var(--card-bg);
    border-radius: 8px;
    padding-left: 15px;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.12);

    .theme-toggle {
      margin-right: 3px;
    }

    @media screen and (max-width: 768px) {
      flex: 0;
    }
  }

  &:not(.transparent) {
    .locale-container {
      box-shadow: none;
      background: transparent;
    }
  }

  .nav {
    align-items: center;
    margin-left: auto;

    .link {
      text-decoration: none;
      padding: 0 15px;
      display: flex;
      align-items: center;
      margin-left: 20px;
      position: relative;
      font-size: 15px;
      color: var(--text);
      background: var(--bg);
      border-radius: 8px;
      transition: box-shadow 250ms;
      height: 46px;

      svg {
        margin-right: 10px;
        height: 20px;
        fill: var(--text);
      }

      &:after {
        content: "";
        position: absolute;
        background-color: var(--primary);
        opacity: 0;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        border-radius: 8px;
        transition: opacity 250ms;
      }

      &.active,
      &:hover {
        box-shadow: 0px 0px 0px 1px var(--primary);

        &:after {
          opacity: 0.1;
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  @media screen and (min-width: 768px) {
    &.transparent {
      background: transparent;
      box-shadow: none;
    }

    &:not(.transparent) {
      .nav .link {
        background: transparent;
      }
    }
  }

  @media screen and (max-width: 768px) {
    background-color: transparent;
    box-shadow: none;
    position: relative;

    .container {
      padding: 15px 0;
      height: auto;
      justify-content: center;

      .logo {
        justify-content: center;
      }

      .nav {
        height: 64px;
        justify-content: center;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 0;
        background: var(--card-bg);
        box-shadow: 0 -2px 6px rgba(0, 0, 0, 0.16);

        .link {
          margin: 0 10px;
          height: 42px;
          padding: 0 25px;
        }
      }
    }
  }

  @media print {
    background-color: transparent;
    box-shadow: none;

    @page:first {
      margin-top: 100px;
    }

    .container {
      justify-content: center !important;
    }
  }
}
